import React, { useState } from 'react';
import { Alert, Box, Typography, Stack, Button, CircularProgress } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import logo from '../../assets/Picker.png';

import HeadWrapper from '../HeadWrapper/HeadWrapper';
import PlayerCard from './PlayerCard';
import Title from '../Title/Title';
import colors from '../../config/colors';

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const diff = Math.floor((now - timestamp) / 1000);

  if (diff < 60) return "Just now";
  if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }
  if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  if (diff < 172800) return "Yesterday";
  if (diff < 604800) {
    const days = Math.floor(diff / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
  if (diff < 2419200) {
    const weeks = Math.floor(diff / 604800);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  }
  if (diff < 31536000) {
    const months = Math.floor(diff / 2419200);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(diff / 31536000);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};

const ActivityPage = ({ selections, error }) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };

  const SelectionList = () => (
    <Stack spacing={2} sx={{ width: '100%', maxWidth: '600px', mx: 'auto' }}>
      {selections.slice(0, visibleCount).map((selection, index) => (
        <Box key={index} sx={{ padding: 2, paddingTop: {xs: 2, sm: 3, md: 4 }, borderTop: '1px solid rgba(0, 0, 0, .125)' }} >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '0.95rem', sm: '1.1rem', md: '1.2rem' } }}>
              {selection.players.length} Player Selection
            </Typography>
            <Typography variant="subtitle1" sx={{ color: colors.primary400, fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '0.85rem', sm: '1rem', md: '1.1rem' } }}>
              {getTimeAgo(new Date(selection.eventTimestamp))}
            </Typography>
          </Box>
          <Stack direction="column" spacing={isSmallScreen ? 1 : 2} sx={{ marginTop: 2 }}>
            {selection.players
              .slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) => a.selectionIndex - b.selectionIndex) // Sort players by selectionIndex
              .map((player, playerIndex) => {
                return (
                  <Box
                    key={playerIndex}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{
                        width: {xs: 26, sm: 32, md: 36},
                        height: {xs: 26, sm: 32, md: 36},
                        borderRadius: '50%',
                        backgroundColor: colors.primary400,
                        border: '1px solid',
                        borderColor: colors.primary500,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontFamily: 'Avenir, Arial, sans-serif',
                        fontWeight: 'bold',
                        zIndex: 100
                      }}
                    >
                      {playerIndex + 1}
                    </Box>
                    <PlayerCard
                      player={player}
                    />
                  </Box>
                );
              })}
          </Stack>
        </Box>
      ))}
      {visibleCount < selections.length ? (
        <Button onClick={handleLoadMore} variant="text" sx={{ mt: 2 }}>
          Load More
        </Button>
      ) : (
        selections.length > 0 && <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary', fontFamily: 'Avenir, Arial, sans-serif' }}>
          You've reached the end! Activity Feed is limited to the most recent selections.
        </Typography>
      )}
    </Stack>
  );
  

  return (
    <>
      <HeadWrapper 
        title="Activity Feed" 
        description="Crowd-sourced dynasty baseball rankings. View the community's most recent selections here!" 
        image={logo} 
      />
      <Title 
        pageName="Activity Feed" 
        extraText="Take a look at the most recent player selections across the community!"
        showPickerLink={false} 
        showRankingsLink={true}  
      />
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Error retrieving previous selections. Please come back later.
        </Alert>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', marginBottom: 2 }}>
        {selections && selections.length > 0 ? (
          <SelectionList />
        ) : (
          !error && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress sx={{ color: colors.primary400 }} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ActivityPage;
