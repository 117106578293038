import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import colors from '../../config/colors';
import PositionBox from '../PositionBox/PositionBox';

const PlayerList = ({ players, isFiltered, isSearched }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [isFiltered, isSearched]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const calculateValueChange = (lastNDaysRanks, currentValue) => {
    let valueChange = 0;
    if (lastNDaysRanks.length > 7) {
      lastNDaysRanks = lastNDaysRanks.slice(-7);
    }
    for (let i = 0; i < lastNDaysRanks.length; i++) {
      const comparisonDayValue = lastNDaysRanks[i];
      if (typeof comparisonDayValue === 'number') {
        valueChange = currentValue - comparisonDayValue;
        break;
      }
    }
    return valueChange;
  }

  const renderValueChangeIcon = (valueChange, index, mediumThreshold) => {
    const largeThreshold = mediumThreshold * 3;
    const Icon = index === 0 ? EmojiEventsIcon : (Math.abs(valueChange) < mediumThreshold ? null : (valueChange > 0 ? (valueChange > largeThreshold ? ArrowDownwardIcon : CallReceivedIcon) : (valueChange < (largeThreshold * -1) ? ArrowUpwardIcon : CallMadeIcon)));
    return (
      Icon && (<Avatar sx={{
        width: { xs: '1.25rem', sm: '1.4rem', md: '1.6rem'}, 
        height: { xs: '1.25rem', sm: '1.4rem', md: '1.6rem'},
        backgroundColor: colors.secondary400,
        title: "Your text explanation here",
        color: 'inherit'
      }}>
        <Icon sx={{ fontSize: { xs: '1.0rem', sm: '1.2rem', md: '1.4rem'} }}/>
      </Avatar>)
    );
  };

  const renderMilbIcon = () => {
    return (
      <Avatar sx={{
        width: { xs: '1.6rem', sm: '1.8rem', md: '2rem' }, 
        height: { xs: '1rem', sm: '1.2rem', md: '1.4rem' },
        borderRadius: '0.2rem', // slight border radius for rounded corners
        backgroundColor: colors.primary400,
        color: 'white',
        p: '1px'
      }}>
        <Typography sx={{ fontSize: { xs: '0.6rem', sm: '.65rem', md: '.75rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>
          MiLB
        </Typography>
      </Avatar>
    );
  };
  

  const renderPlayerRow = (player, index) => {
    const valueChange = calculateValueChange(player.valueHistory30Days.map(dayValue => dayValue !== null ? dayValue.value : null), player.value);
  
    return (
      <TableRow 
        key={`${player.name}-${index}`}
      >
        <TableCell style={{ width: isFiltered ? '15%' : '10%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 } }}>
          {isFiltered ? 
            `${(index + 1) + (page * 50)} · ${player.originalIndex + 1}` : 
            (player.originalIndex + 1)
          }
        </TableCell>
        <TableCell style={{ width: '20%' }} sx={{ p: { xs: '2px', sm: '3px', md: '4px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 } }}>
          {player.name ? 
            <Typography
              sx={{ 
                textDecoration: 'underline', 
                color: 'inherit', 
                fontFamily: 'Avenir, Arial, sans-serif',
                fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem'},
                fontWeight: 500 
                }}
              component="a"
              href={`/player/${player.id}`}
              target="_blank"
              rel="noopener noreferrer"
              >
              {player.name}
            </Typography> 
            : '–'}
        </TableCell>

        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>{player.age ? player.age : '–'}</TableCell>
        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>
          {player.positions ? player.positions.map((position, index) => <PositionBox key={index} position={position} />) : '–'}
        </TableCell>
        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500, display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>{player.team ? player.team : '–'}</TableCell>
        <TableCell style={{ width: '20%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500  }}>
          <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
            {player.value.toLocaleString()}
            <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
              {renderValueChangeIcon(valueChange * -1, player.originalIndex, Math.max((player.value / 120), 10))}
            </Box>
            {(player.assetType === "PLAYER" && !player.majorLeaguer) && 
              <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: {xs: .5, sm: 1 } }}>
                {renderMilbIcon()}
              </Box>
            }
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  
  const renderHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: isFiltered ? '15%' : '10%'}} sx={{ width: '8%', fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}></TableCell>
        <TableCell style={{ width: '20%' }} sx={{ p: { xs: '2px', sm: '3px', md: '4px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>Name</TableCell>
        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 } }}>Age</TableCell>
        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 } }}>Position</TableCell>
        <TableCell style={{ width: '15%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }, display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>Team</TableCell>
        <TableCell style={{ width: '20%' }} sx={{ p: { xs: '10px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 } }}>Value</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderPagination = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
      <Button
        onClick={() => handleChangePage(Math.max(0, page - 1))}
        disabled={page === 0}
        sx={{ fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}
      >
        Previous
      </Button>
      <Button
        onClick={() => handleChangePage(Math.min(Math.max(Math.ceil(players.length / 50 - 1), 1), page + 1))}
        disabled={page >= Math.ceil(players.length / 50) - 1}
        sx={{ fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}
      >
        Next
      </Button>
    </Box>
  );

  const rowsPerPage = 50;
  const currentPage = page;
  const start = currentPage * rowsPerPage;
  const end = Math.min(players.length, start + rowsPerPage);
  const playersForCurrentPage = players.slice(start, end);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', paddingLeft: '2px', paddingRight: '2px', flexDirection: 'column', width: '100%',
      '&.MuiContainer-root': {
        paddingLeft: { sm: '2px' },
        paddingRight: { sm: '2px' }
      } }}>
      <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }} >
        <Table>
          {renderHeader()}
          <TableBody>
            {playersForCurrentPage.length > 0 ? playersForCurrentPage.map((player, index) => {
              return renderPlayerRow(player, index);
            }) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography sx={{ padding: '16px', fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' } }}>
                  No players match the filter.
                </Typography>
              </TableCell>
            </TableRow>          
          )}
          </TableBody>
        </Table>
        {renderPagination()}
        </TableContainer>
    </Container>
  );
};

export default PlayerList;
