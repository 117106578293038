import React, { useState, useEffect } from 'react';
import { getPlayerRankings } from '../../api/apiService';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Box, Typography, Container, Paper, Slider, IconButton } from '@mui/material';
import TradePackageBuilder from './TradePackageBuilder';
import Title from '../Title/Title';
import SuccessSnackbar from './SuccessSnackbar';
import HeadWrapper from '../HeadWrapper/HeadWrapper';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import logo from '../../assets/TradeCalculator.png';


const TradeCalculator = () => {
  const [teamOnePlayers, setTeamOnePlayers] = useState([]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([]);
  const [playerRankings, setPlayerRankings] = useState([]);
  const [rankingsLoaded, setRankingsLoaded] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate(); // For programmatically navigating
  const location = useLocation(); // To access query parameters

  useEffect(() => {
    getPlayerRankings().then(response => {
      setErrorResponse(false);
      setPlayerRankings(response.data);
      setRankingsLoaded(true);
    })
    .catch(error => {
      setErrorResponse(true);
      console.error("Failed to fetch players:", error);
    });
  }, []);

  // Parse the URL and update team players based on playerRankings
  useEffect(() => {
    if(rankingsLoaded) {
      const searchParams = new URLSearchParams(location.search);
      const teamOneIds = searchParams.get('teamOne')?.split(',') || [];
      const teamTwoIds = searchParams.get('teamTwo')?.split(',') || [];
      
      // Filter playerRankings based on the IDs from the URL
      const filteredTeamOnePlayers = playerRankings.filter(player => teamOneIds.includes(player.id.toString()));
      const filteredTeamTwoPlayers = playerRankings.filter(player => teamTwoIds.includes(player.id.toString()));
      setTeamOnePlayers(filteredTeamOnePlayers);
      setTeamTwoPlayers(filteredTeamTwoPlayers);
    }
  }, [location.search, rankingsLoaded]);

  useEffect(() => {
    if(rankingsLoaded) {
      updateURL();
    }
  }, [teamOnePlayers, teamTwoPlayers]);

    // Function to serialize player IDs into a query string
    const serializePlayerIds = (players) => players.map(player => player.id).join(',');

    // Function to update the URL
    const updateURL = () => {
      const teamOneIds = serializePlayerIds(teamOnePlayers);
      const teamTwoIds = serializePlayerIds(teamTwoPlayers);
      navigate(`?teamOne=${teamOneIds}&teamTwo=${teamTwoIds}`);
    };

    const handleCopy = () => {
      const teamOneIds = serializePlayerIds(teamOnePlayers);
      const teamTwoIds = serializePlayerIds(teamTwoPlayers);
    
      // Create formatted player names with emojis based on their value
      const formatPlayer = (player) => {
        let emoji;
        if (player.value >= 3000) {
          emoji = '💪'.repeat(Math.floor(player.value / 3000));
        } else if (player.value >= 1250) {
          emoji = '👍'.repeat(Math.floor(player.value / 1250));
        } else {
          emoji = '😐';
        }
        return `${player.name} ${emoji}`;
      };
    
      const teamOneNames = teamOnePlayers.map(formatPlayer).join('\n');
      const teamTwoNames = teamTwoPlayers.map(formatPlayer).join('\n');
    
      // Create trade evaluation text
      const evaluation = success
        ? 'This trade is even!'
        : `This trade ${tradeDifference > 0 ? 'favors Team 2' : 'favors Team 1'} by ${Math.abs(tradeDifference).toLocaleString()} value points.`;

// Construct the trade summary text
const tradeSummary = `Trade Summary:

Team 1 receives:

${teamOneNames}

Team 2 receives:

${teamTwoNames}

${evaluation}

View this trade and others on HarryKnowsBall: https://harryknowsball.com/calculator?teamOne=${teamOneIds}&teamTwo=${teamTwoIds}`;

      // Copy to clipboard
      navigator.clipboard.writeText(tradeSummary);
      setSnackbarOpen(true);
    };
    

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

    const calculateSliderMarkOffset = () => {
      const totalValue = teamOneValue + teamTwoValue;
      const linearValue = totalValue ? (((totalValue / 2) - (acceptanceThreshold / 2)) / totalValue) * 100 : 50; // Reflects Team 2's share
    
      const denormalizedSliderValue = denormalizeSliderValue(linearValue);
      const offset = Math.abs(denormalizedSliderValue - 50.0);
      
      // Calculate the two desired return values
      const valueMinusOffset = Math.max(0, 50.0 - offset);
      const valuePlusOffset = Math.min(100, 50.0 + offset);
    
      // Return these values as an array
      return [valueMinusOffset, valuePlusOffset];
    };

  const calculateSliderValue = () => {
    const totalValue = teamOneValue + teamTwoValue;
    const linearValue = totalValue ? (teamTwoValue / totalValue) * 100 : 50; // Reflects Team 2's share
  
    return denormalizeSliderValue(linearValue);
  };
  
  const denormalizeSliderValue = (value) => {
    let tempValue = value;
    if (value > 50) {
      tempValue = 100 - tempValue;
    }

    if(tempValue < 25) {
      tempValue /= 2.2;
    } else if(tempValue < 40.5) {
      tempValue /= 1.9;
    } else if(tempValue < 45) {
      tempValue /= 1.7;
    } else if(tempValue < 47) {
      tempValue /= 1.5;
    } else if(tempValue < 49) {
      tempValue /= 1.2;
    }

    if (value > 50) {
      tempValue = 100 - tempValue; // Re-adjusting for correct scale after modification
    }

    return tempValue;
  };

  const calculateTeamValue = (teamPlayers, otherTeamPlayers) => {

    // Sort players by value in descending order
    const sortedPlayers = [...teamPlayers].sort((a, b) => (b.value + b.excessValue) - (a.value + a.excessValue));
  
    // Initial team value without depreciation
    let teamValue = sortedPlayers.reduce((total, player) => total + player.value + player.excessValue, 0);
  
    // Apply depreciation starting from the second player, with increasing percentages
    const valuesWithDepreciation = sortedPlayers.map((player, index) => {
        if(index >= 1) {
          const depreciationRate = Math.min(.8, (.35 + (index * .10)));
          // const depreciationRelaxer = Math.max(1, 2 - (index / 2));
          return (player.value + player.excessValue) * (1 - (depreciationRate));
        } else {
          return (player.value + player.excessValue);
        }
    });
  
    const totalValueWithDepreciation = valuesWithDepreciation.reduce((total, currentValue) => total + currentValue, 0);
  
    const depreciationValue = Math.floor(teamValue - totalValueWithDepreciation);
  
    return { teamValue, depreciationValue };
  };

  const  { teamValue: teamOneValueWithoutDepreciation, depreciationValue: teamOneDepreciation } = calculateTeamValue(teamOnePlayers, teamTwoPlayers);
  const  { teamValue: teamTwoValueWithoutDepreciation, depreciationValue: teamTwoDepreciation } = calculateTeamValue(teamTwoPlayers, teamOnePlayers);
  
  let adjustedTeamOneDepreciation = teamOneDepreciation;
  let adjustedTeamTwoDepreciation = teamTwoDepreciation;
  
  // Only keep the highest depreciation amount and subtract the other from that
  if (teamOneDepreciation > teamTwoDepreciation) {
    adjustedTeamOneDepreciation -= teamTwoDepreciation;
    adjustedTeamTwoDepreciation = 0; // Since we subtract the entire amount from the lower depreciation
  } else {
    adjustedTeamTwoDepreciation -= teamOneDepreciation;
    adjustedTeamOneDepreciation = 0; // Since we subtract the entire amount from the lower depreciation
  }

  const getMaxPlayerValue = (players) => {
    return Math.max(...players.map(player => player.value + player.excessValue));
  };

  const maxTeamOnePlayerValue = getMaxPlayerValue(teamOnePlayers);
  const maxTeamTwoPlayerValue = getMaxPlayerValue(teamTwoPlayers);

  let teamOneValue = teamOneValueWithoutDepreciation - adjustedTeamOneDepreciation;
  let teamTwoValue = teamTwoValueWithoutDepreciation - adjustedTeamTwoDepreciation;

  if (adjustedTeamOneDepreciation > 0 && maxTeamOnePlayerValue > maxTeamTwoPlayerValue && teamOneValue > teamTwoValue) {
    teamOneValue += adjustedTeamOneDepreciation;
    adjustedTeamOneDepreciation = 0;
  } else if(adjustedTeamTwoDepreciation > 0 && maxTeamTwoPlayerValue > maxTeamOnePlayerValue && teamTwoValue > teamOneValue) {
    teamTwoValue += adjustedTeamTwoDepreciation;
    adjustedTeamTwoDepreciation = 0;
  }

  const tradeDifference = teamTwoValue - teamOneValue;
  const acceptanceThreshold = teamOneValue !== 0 || teamTwoValue !== 0 ? Math.max(200, (Math.max(teamOneValue, teamTwoValue) / 13.5)) : null;
  const success = teamOnePlayers.length && teamTwoPlayers.length && Math.abs(tradeDifference) < acceptanceThreshold;

  const sliderMarks = acceptanceThreshold ? (() => {
    const [valueMinusOffset, valuePlusOffset] = calculateSliderMarkOffset();
  
    return [
      {
        value: valueMinusOffset,
        label: '',
      },
      {
        value: valuePlusOffset,
        label: '',
      },
    ];
  })() : [];

  return (
    <>
      <HeadWrapper 
        title="Trade Calculator" 
        description="100% crowd-sourced player and pick values give you the most accurate, up-to-date trade evaluations." 
        image={logo} 
      />
      <Title pageName="Trade Calculator" extraText="Dynasty, head-to-head, standard points scoring. Select one or more players or picks for each side." showPickerLink={true} showRankingsLink={false}  />
      <Container width="md">
      {errorResponse && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>Error retrieving player set. Please come back later.</Alert>
      )}
      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          gap: 6,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 4,
          },
          backgroundColor: 'white'
        }}> 
          <TradePackageBuilder 
            playerRankings={playerRankings}
            teamNumber={1}
            teamPlayers={teamOnePlayers}
            setTeamPlayers={setTeamOnePlayers}
            teamValue={teamOneValue}
            depreciationValue={adjustedTeamOneDepreciation}
            success={success}
          />
          <TradePackageBuilder 
            playerRankings={playerRankings}
            teamNumber={2}
            teamPlayers={teamTwoPlayers}
            setTeamPlayers={setTeamTwoPlayers}
            teamValue={teamTwoValue}
            depreciationValue={adjustedTeamTwoDepreciation}
            success={success}
          />
        </Box>
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              ...(acceptanceThreshold != null && {
                bgcolor: success ? '#EDF7ED' : '#FDEDED',
                border: success ? '1px solid' : '',
                borderColor: success ? 'success.main' : 'error.main',
              }),
              [theme.breakpoints.up('md')]: {
                width: '80%',
              },
            }}
          >
          <Slider
            value={calculateSliderValue()}
            aria-labelledby="trade-balance-slider"
            marks={sliderMarks}
            sx={{
              maxWidth: '85%',
              '& .MuiSlider-track, & .MuiSlider-rail': {
                color: 'grey.400',
              },
              '& .MuiSlider-rail': {
                opacity: 1.0,
                height: '6px'
              },
              ...(acceptanceThreshold != null && {
                '& .MuiSlider-thumb': {
                  color: success ? 'success.main' : 'error.main',
                },
                '& .MuiSlider-track, & .MuiSlider-rail': {
                  color: success ? 'success.light' : 'error.light',
                },
                '& .MuiSlider-rail': {
                  opacity: 1.0,
                  height: '6px'
                },
                '& .MuiSlider-mark': {
                  backgroundColor: success ? 'success.light' : 'error.light',
                  width: '2px',
                  height: '30px',
                },
              })
            }}
            disabled
          />
          <div style={{ width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption" style={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>Team 1</Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
              <div style={{ visibility: tradeDifference < 0 ? 'visible' : 'hidden' }}>
                <ArrowLeftIcon style={{ fontSize: '2rem' }} sx={{ pt: '3px' }} />
              </div>
              <Typography variant="body2" style={{ padding: '0 2px', fontFamily: 'Avenir, Arial, sans-serif' }}>
                {Math.abs(tradeDifference).toLocaleString()}
              </Typography>
              <div style={{ visibility: tradeDifference > 0 ? 'visible' : 'hidden' }}>
                <ArrowRightIcon style={{ fontSize: '2rem' }} sx={{ pt: '3px'}} />
              </div>
            </div>
            <Typography variant="caption" style={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>Team 2</Typography>
          </div>
          </Paper>
          {acceptanceThreshold !== null && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <Alert
                severity={success ? 'success' : 'info'}
                sx={{ 
                  flex: 1, 
                  fontFamily: 'Avenir, Arial, sans-serif', 
                  backgroundColor: !success && 'grey.200',
                  color: !success && 'grey.800', // Text color
                  '& .MuiAlert-icon': {
                    color: !success && 'grey.600', // Icon color
                  } }}
              >
                {success ? 'This trade is even' : `This trade ${tradeDifference > 0 ? 'favors Team 2' : 'favors Team 1'}`}
              </Alert>
              <IconButton
                onClick={handleCopy}
                sx={{ border: '1px solid', borderRadius: 1, marginLeft: 1, p: 1.25 }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          )}
          <SuccessSnackbar open={snackbarOpen} handleSnackbarClose={handleSnackbarClose} message="Successfully copied trade!" />
        </Box>
      </Container>
    </>
  );
};

export default TradeCalculator;
