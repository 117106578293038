import axios from 'axios';

// Use an environment variable for the API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const generateFourSelection = (payload) => {
  return api.post('/hkb/generateFourSelection', payload);
};

export const generateTwoSelection = () => {
  return api.get('/hkb/generateTwoSelection');
};

export const rankFourPlayers = (payload) => {
  return api.post('/hkb/rankFourPlayers', payload);
};

export const rankTwoPlayers = (payload) => {
  return api.post('/hkb/rankTwoPlayers', payload);
};

export const getPlayerRankings = () => {
  return api.get('/hkb/playerRankings');
};

export const getPlayer = (payload) => {
  return api.post('/hkb/player', payload);
};

export const getPlayerWithFullHistory = (payload) => {
  return api.post('/hkb/player/fullHistory', payload);
};

export const getSelectionsCount = () => {
  return api.get('/hkb/selectionsCount');
};

export const getLast100Selections = () => {
  return api.get('/hkb/selectionHistory');
}

export const getJWARSeason = (payload) => {
  return api.post('/jwar/getSeason', payload);
};