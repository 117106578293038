import React from 'react';
import { Box, Typography, Paper, Stack } from '@mui/material';
import colors from '../../config/colors';

const PlayerCard = ({ player }) => (
  <Paper
    sx={{
      py: { xs: 1.5, sm: 1.75, md: 2 },
      px: { xs: 1.5, sm: 1.75, md: 2.5 },
      border: '1px solid',
      backgroundColor: colors.secondary100,
      borderColor: colors.secondary500,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      marginLeft: { xs: -2, sm: -2.25, md: -2.5 },
    }}
  >
    <Typography
      sx={{
        fontWeight: 600,
        color: 'black',
        paddingLeft: '3px',
        fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' },
        fontFamily: 'Avenir, Arial, sans-serif',
      }}
      component="a"
      href={`/player/${player.playerId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {player.name}
    </Typography>

    <Box>
      {player.assetType === 'PLAYER' ? (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' } }}>
            {player.positions.join(", ")}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' }, minWidth: {xs: '30px', sm: '40px'}, maxWidth: 'fit-content', textAlign: 'right' }}>
            {player.age}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.75rem', sm: '.95rem', md: '1.15rem' } }}>
            {player.assetType}
          </Typography>
        </Box>
      )}
    </Box>
  </Paper>
);

export default PlayerCard;
