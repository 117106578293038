import React, { useState, useEffect } from 'react';
import { Alert, TextField, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, IconButton, Collapse } from '@mui/material';
import Title from '../Title/Title';
import JWarFilters from '../Filter/JWarFilters';
import LeagueInput from './LeagueInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { getJWARSeason } from '../../api/apiService';
import JWARPlayerDetails from './JWARPlayerDetails';
import { debounce } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import PositionBox from '../PositionBox/PositionBox';

import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/jWAR.png';

const JWar = () => {
  const [players, setPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [playersPerPage] = useState(50);
  const [orderJWAR, setOrderJWAR] = useState('desc');
  const [orderJWARA, setOrderJWARA] = useState('desc');
  const [orderBy, setOrderBy] = useState('jWAR');
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [filterText, setFilterText] = useState('');
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [leagueSettingConfig, setLeagueSettingConfig] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [openJWARInfo, setOpenJWARInfo] = useState(false);
  const [openJWARAInfo, setOpenJWARAInfo] = useState(false);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const closeTooltip = (setOpen) => () => setOpen(false);

  const handleJWARInfoToggle = () => (event) => {
    event.stopPropagation();
    setOpenJWARInfo(!openJWARInfo);
  };

  const handleJWARAInfoToggle = () => (event) => {
    event.stopPropagation();
    setOpenJWARAInfo(!openJWARAInfo);
  };

  const handleClearSearch = () => {
    setFilterText('');
  };

  useEffect(() => {
    if (isTouchDevice()) {
      const closeTooltip1 = closeTooltip(setOpenJWARInfo);
      if (openJWARInfo) {
        document.addEventListener('click', closeTooltip1);
      }
      return () => document.removeEventListener('click', closeTooltip1);
    }
  }, [openJWARInfo]);

  useEffect(() => {
    if (isTouchDevice()) {
      const closeTooltip2 = closeTooltip(setOpenJWARAInfo);
      if (openJWARAInfo) {
        document.addEventListener('click', closeTooltip2);
      }
      return () => document.removeEventListener('click', closeTooltip2);
    }
  }, [openJWARAInfo]);

  useEffect(() => {
    const debounceFilter = debounce((searchText) => {
      const filtered = players
        .filter(player =>
          player.Name.toLowerCase().includes(searchText.toLowerCase())
        )
        .filter(player =>
          selectedPositions.length === 0 || selectedPositions.some(selectedPosition => 
            player.positions.includes(selectedPosition)
          )
        );
      setCurrentPage(1);
      setDisplayedPlayers(filtered);
    }, 250);

    debounceFilter(filterText);
    return () => debounceFilter.cancel();
  }, [filterText, selectedPositions, players]);

  const handlePositionChange = (event, newPosition) => {
    // Check if the position is already selected
    if (selectedPositions.includes(newPosition)) {
      // If so, remove it from the list (toggle off)
      setSelectedPositions(selectedPositions.filter(position => position !== newPosition));
    } else {
      // If not, add it to the list (toggle on)
      setSelectedPositions([...selectedPositions, newPosition]);
    }
  };

  const fetchPlayers = () => {
    setIsLoading(true);
    setPlayers([]);
    getJWARSeason(leagueSettingConfig).then(response => {
      let playersData = response.data.map(player => {
        const positionToJWARMapping = {
          'SP': player.SP_jWAR,
          'RP': player.RP_jWAR,
          'C': player.C_jWAR,
          '1B': player['1B_jWAR'],
          '2B': player['2B_jWAR'],
          '3B': player['3B_jWAR'],
          'SS': player.SS_jWAR,
          'OF': player.OF_jWAR,
          'UT': player.UT_jWAR,
        };

        const positionToJWARAMapping = {
          'SP': player.SP_jWARa,
          'RP': player.RP_jWARa,
          'C': player.C_jWARa,
          '1B': player['1B_jWARa'],
          '2B': player['2B_jWARa'],
          '3B': player['3B_jWARa'],
          'SS': player.SS_jWARa,
          'OF': player.OF_jWARa,
          'UT': player.UT_jWARa,
        };
  
        const positions = [player.pos1, player.pos2, player.pos3, player.pos4, player.pos5]
          .filter(Boolean)
          .filter(pos => pos in positionToJWARAMapping);
        const jWARValues = positions.map(pos => positionToJWARMapping[pos]);
        const jWARAValues = positions.map(pos => positionToJWARAMapping[pos]);
        let highestJWAR = Math.max(...jWARValues.filter(value => value != null));
        let highestJWARA = Math.max(...jWARAValues.filter(value => value != null));

        if(player.Name === "Shohei Ohtani") {
          highestJWAR = jWARValues.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);

          highestJWARA = jWARAValues.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        }
  
        return {
          ...player,
          Position: positions.join(', '),
          jWAR: highestJWAR,
          jWARa: highestJWARA,
          positions: positions,
          jWARValues: jWARValues,
          jWARAValues: jWARAValues,
        };
      });

      playersData = assignOverallRanks(playersData, 'jWAR');
      playersData = assignOverallRanks(playersData, 'jWARa');

      ['SP', 'RP', 'C', '1B', '2B', '3B', 'SS', 'OF', 'UT'].forEach(position => {
        for(let i = 0; i < playersData.length; i++) {
          if(playersData[i].positions.includes(position)) {
            playersData = assignOverallRanks(playersData, position + "_jWAR");
            playersData = assignOverallRanks(playersData, position + "_jWARa");
          }
        }
      });
      setErrorResponse(false)
      setDisplayedPlayers(playersData);
      setPlayers(playersData);
      setIsLoading(false);
    }).catch(error => {
      setErrorResponse(true);
      console.error("Failed to fetch players:", error);
      setIsLoading(false); // Stop loading in case of error
    });
  };

  const assignOverallRanks = (players, key) => {
    // Sort players by the key (e.g., 'jWAR') in descending order
    const sortedPlayers = [...players].sort((a, b) => b[key] - a[key]);
  
    // Assign ranks based on sorted order
    let rank = 1;
    sortedPlayers.forEach((player, index, array) => {
      if (index > 0 && player[key] === array[index - 1][key]) {
        // If current player's jWAR is the same as the previous player's, they share the same rank
        player[`${key}Rank`] = rank - 1;
      } else {
        // Otherwise, the current player's rank is their position in the sorted array + 1
        player[`${key}Rank`] = rank;
      }
      rank++;
    });
  
    return sortedPlayers;
  }

  const getCurrentRank = (player) => {
    return player[`${orderBy}Rank`];
  }

  const toggleRowExpansion = (playerId) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(playerId)) {
      newExpandedRows.delete(playerId);
    } else {
      newExpandedRows.add(playerId);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleRequestSortJWAR = () => {
    if(orderBy === 'jWARa') {
      setOrderJWAR('desc');
    } else {
      const isAsc = orderJWAR === 'asc';
      setOrderJWAR(isAsc ? 'desc' : 'asc');
    }
    setOrderBy('jWAR');
  };

  const handleRequestSortJWARA = () => {
    if(orderBy === 'jWAR') {
      setOrderJWARA('desc');
    } else {
      const isAsc = orderJWARA === 'asc';
      setOrderJWARA(isAsc ? 'desc' : 'asc');
    }
    setOrderBy('jWARa');
  };

  const sortedPlayers = () => {
    return [...displayedPlayers].sort((a, b) => {
      if (orderBy === 'jWAR') {
        return orderJWAR === 'asc' ? a.jWAR - b.jWAR : b.jWAR - a.jWAR;
      } else if (orderBy === 'jWARa') {
        return orderJWARA === 'asc' ? a.jWARa - b.jWARa : b.jWARa - a.jWARa;
      }
      return 0;
    });
  };

  const clearFilters = () => {
    setSelectedPositions([]);
  };

  const positions = ["C", "1B", "2B", "3B", "SS", "OF", "SP", "RP"];

  // Pagination logic
  const indexOfLastPlayer = currentPage * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = sortedPlayers().slice(indexOfFirstPlayer, indexOfLastPlayer);

  return (
    <>
      <HeadWrapper 
        title="jWAR" 
        description="jWAR is a metric to track fantasy baseball wins above replacement by position, using customizable league settings." 
        image={logo} 
      />
      <Title pageName="JWAR" extraText="jWAR determines fantasy wins above replacement based on your league settings!" showPickerLink={true} showRankingsLink={false} />

      {errorResponse && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>Error retrieving jWAR. Please come back later.</Alert>
      )}
      <LeagueInput 
        setLeagueSettingConfig={setLeagueSettingConfig} 
        fetchPlayers={fetchPlayers} 
        isLoading={isLoading}
      />
      {players.length > 0 && 
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', marginBottom: '20px', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
                label="Search Players"
                variant="outlined"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                sx={{
                  marginTop: '10px',
                  width: '100%',
                  '& .MuiInputLabel-root': {
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontWeight: 500
                  },
                  '& .MuiInputBase-input': {
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontWeight: 500
                  },
                }}
              />
              {filterText && 
                <IconButton
                size="small"
                onClick={handleClearSearch}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: '58%',
                  transform: 'translateY(-50%)',
                }}
              >
                  <ClearIcon />
                </IconButton>
              }
            </Box>
            <JWarFilters
                positions={positions}
                selectedPositions={selectedPositions}
                onPositionChange={handlePositionChange}
                clearFilters={clearFilters}
              />
            <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }} >
            <Table>
              <TableHead>
                <TableRow>
                  {/* New column for accordion toggle */}
                  <TableCell  style={{ width: '8%' }} />
                  <TableCell style={{ width: '20%', paddingLeft: '0px' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.6rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>Name</TableCell>
                  <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.6rem', sm: '0.85rem', md: '1rem' }, display: { xs: 'none', sm: 'none', md: 'table-cell' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>Age</TableCell>
                  <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.6rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>Position</TableCell>
                  <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.6rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: .7 }}>
                      <TableSortLabel
                        active={orderBy === 'jWAR'}
                        direction={orderJWAR}
                        onClick={handleRequestSortJWAR}
                        IconComponent={() => <span />} // Hide the default icon
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          },
                          '&.Mui-active': {
                            color: 'primary.main',
                          },
                          fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500
                        }}
                      >
                        jWAR
                      </TableSortLabel>
                      <Tooltip title="Fantasy Wins Above Replacement"
                        onClick={isTouchDevice() ? handleJWARInfoToggle() : undefined}
                        onMouseEnter={!isTouchDevice() ? handleJWARInfoToggle() : undefined}
                        onMouseLeave={!isTouchDevice() ? handleJWARInfoToggle() : undefined}
                        open={openJWARInfo}
                      >
                        <IconButton size="small" sx={{ ml: { xs: -1, sm: -.5 }, fontSize: { xs: '1.00rem', sm: '1.5rem' } }}>
                          <InfoIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      {orderBy === 'jWAR' && (
                        orderJWAR === 'desc' ? <KeyboardArrowDownIcon sx={{ ml: { xs: -1.5, sm: -1 }, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}/> : <KeyboardArrowUpIcon sx={{ ml: { xs: -1.5, sm: -1 }, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}/>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.6rem', sm: '0.85rem', md: '1rem' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: .7 }}>
                      <TableSortLabel
                        active={orderBy === 'jWARa'}
                        direction={orderJWARA}
                        onClick={handleRequestSortJWARA}
                        IconComponent={() => <span />} // Hide the default icon
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          },
                          '&.Mui-active': {
                            color: 'primary.main',
                          },
                          fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500
                        }}
                      >
                        jWARa
                      </TableSortLabel>
                      <Tooltip title="Full Season Adjusted jWAR using PPG"
                        onClick={isTouchDevice() ? handleJWARAInfoToggle() : undefined}
                        onMouseEnter={!isTouchDevice() ? handleJWARAInfoToggle() : undefined}
                        onMouseLeave={!isTouchDevice() ? handleJWARAInfoToggle() : undefined}
                        open={openJWARAInfo}
                      >
                        <IconButton size="small" sx={{ ml: { xs: -1, sm: -.5 }, fontSize: { xs: '1.00rem', sm: '1.5rem' } }}>
                          <InfoIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      {orderBy === 'jWARa' && (
                        orderJWARA === 'desc' ? <KeyboardArrowDownIcon sx={{ ml: { xs: -1.5, sm: -1 }, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}/> : <KeyboardArrowUpIcon sx={{ ml: { xs: -1.5, sm: -1 }, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}/>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPlayers.map((player, index) => (
                  <React.Fragment key={`${player.Name}-${player.Position}-${index}`}>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                      {/* Accordion toggle cell */}
                      <TableCell style={{ width: '8%', paddingLeft: '0px', paddingTop: '6px', paddingBottom: '6px' }} sx={{fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500}}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
                          <IconButton size="small" onClick={() => toggleRowExpansion(player.Name)}>
                            {expandedRows.has(player.Name) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                          {getCurrentRank(player)}
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: '20%', paddingLeft: '0px' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>{player.Name}</TableCell>
                      <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, display: { xs: 'none', sm: 'none', md: 'table-cell' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>{player.Age}</TableCell>
                      <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>
                        {player.positions ? player.positions.map((position, idx) => <PositionBox key={`${player.Name}-${position}-${idx}`} position={position} />) : '–'}
                      </TableCell>
                      <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>{player.jWAR.toFixed(2)}</TableCell>
                      <TableCell style={{ width: '15%' }} sx={{ p: { xs: '6px', sm: '12px', md: '16px' }, fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500 }}>{player.jWARa.toFixed(2)}</TableCell>
                    </TableRow>
                    {/* Expanded row details */}
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={expandedRows.has(player.Name)} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <JWARPlayerDetails player={player} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" my={2}>
            <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            <Button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(displayedPlayers.length / playersPerPage)}>
              Next
            </Button>
          </Box>
        </Box>
      }
  </>
  );
};

export default JWar;
