import React from 'react';
import { Snackbar } from '@mui/material';

const SuccessSnackbar = ({ message, open, handleSnackbarClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleSnackbarClose}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#F5F5F5', // Light grey background
          border: '1px solid',
          borderColor: '#B0BEC5', // Grey border color
          fontFamily: 'Avenir, Arial, sans-serif',
          color: '#424242', // Dark grey text color
          fontSize: '1rem',
          padding: '8px 16px',
          maxWidth: 'fit-content', // Adjust width to fit the content
          marginBottom: '15px', // Center align the Snackbar content
        },
      }}
      message={message}
    />
  );
};

export default SuccessSnackbar;
